<template>
	<div>
		<el-form :inline="true" :model="params" size="mini">
			<el-form-item>
				<el-input v-model="params.keywords" placeholder="关键词"></el-input>
			</el-form-item>
			<el-form-item>
				<el-select v-model="params.type" placeholder="类型" filterable clearable>
					<el-option v-for="(type, t) in types" :key="t" :label="type" :value="t"/>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="getLuckydraws({ ...params, page: 1})">查询</el-button>
				<el-button type="primary" @click="$utils.toRoute('/luckydraws/records')" :disabled="!$utils.checkAuth('luckydraws/records', 'records')">抽奖记录</el-button>
				<el-button type="primary" @click="$utils.toRoute('/luckydraws/create')" :disabled="!$utils.create('luckydraws/create')">创建抽奖</el-button>
			</el-form-item>
		</el-form>
		<el-row :gutter="20">
			<el-col v-for="draw in draws" :key="draw.id" :xs="12" :sm="12" :md="10" :lg="8" :xl="6">
				<el-card>
					<component
						style="margin: 0 auto;"
						:ref="'lucky_'+draw.id"
						:is="draw.type"
						:width="draw.config.width"
						:height="draw.config.height"
						:prizes="draw.config.prizes"
						:blocks="draw.config.blocks"
						:slots="draw.config.slots"
						:buttons="draw.config.buttons"
						:activeStyle="draw.config.activeStyle"
						:defaultStyle="draw.config.defaultStyle"
						:defaultConfig="draw.config.defaultConfig"
						@start="startCallback(draw.id)"
					/>
					<h4 style="margin-top: 5px; line-height: 2;">{{draw.name}}<font style="color: #999;font-weight: 500;">@{{draw.user.username}}</font></h4>
					<div style="display: flex; justify-content: space-between; font-size: 12px; color: #999; line-height: 1.5;">
						<div>
							开始：{{draw.begin_at}}<br>
							结束：{{draw.end_at}}
						</div>
						<div>
							更新：{{draw.updated_at}}<br>
							创建：{{draw.created_at}}
						</div>
					</div>
				</el-card>
			</el-col>
		</el-row>
		<el-pagination
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => getLuckydraws({ ...this.params, page: 1, perPage: v})"
			@current-change="(v) => getLuckydraws({ ...this.params, page: v})"
			background/>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	export default {
		computed: {
			...mapState(['theme'])
		},
		methods: {
			onDelete (r) {
				this.$confirm('确定要删除【'+r.title+'】抽奖活动吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_LUCKYDRAWS, {params: {action: 'destroy', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getLuckydraws(this.params);
					this.$message.success(msg);
				})
			},
			async submitDraws (data) {
				const res = await this.$http.post(this.$api.URI_LUCKYDRAWS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg
				})
			},
			async getLuckydraws (params) {
				const res = await this.$http.get(this.$api.URI_LUCKYDRAWS, { params });
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.draws = result.data;
				this.types = result.types;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			startCallback (i) {
				const id = 'lucky_'+i;
				// 调用抽奖组件的play方法开始游戏
				this.$refs[id][0].play()
				// 模拟调用接口异步抽奖
				setTimeout(() => {
					// 假设后端返回的中奖索引是0
					const index = 0
					// 调用stop停止旋转并传递中奖索引
					this.$refs[id][0].stop(index)
				}, 3000)
			}
		},
		data() {
			return {
				draws: [],
				types: [],
				params: {
					perPage: 10,
					type: []
				}
			}
		},
		async created () {
			this.getLuckydraws(this.params);
		}
	};
</script>